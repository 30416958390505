import React from 'react'

import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'

import PROBATE_JSONLD from 'lib/json-ld/probate'
import JSONLD from 'components/JSONLD'

import { PRODUCTS } from 'lib/products/constants'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import Content from '../../public/Apply/Content'

const SolicitorsQuote = () => (
  <Wrapper
    style={{
      position: 'relative',
      overflowX: 'hidden',
    }}
  >
    <JSONLD data={PROBATE_JSONLD} />
    <DefaultLayout
      title="Need Help With Probate? | Apply For Probate The Simpler Way"
      description={`Our award-winning team makes applying for probate quick, easy and affordable. Free phone support. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers. Client Care Initiative of the Year 2020.`}
      product={PRODUCTS.PROBATE}
      headerColor={COLOR.WHITE}
      headerType="compact"
      footerType="compact"
      meta={[
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ]}
      footerShowFwlsDisclaimer
    >
      <Content ctaText="Get a quote in 5 minutes" />
    </DefaultLayout>
  </Wrapper>
)

export default SolicitorsQuote
